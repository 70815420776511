@import "variables.css";

@font-face {
  font-family: 'Cadiz';
  src:         url('https://s3.eu-central-1.amazonaws.com/truetaleof.com/CadizWeb-SemiBold.woff2') format("woff2");

  font-weight: 600;
  font-style:  normal;
}

@font-face {
  font-family: 'Cadiz';
  src:         url('https://s3.eu-central-1.amazonaws.com/truetaleof.com/CadizWeb-Regular-subset.woff') format("woff");

  font-weight: 400;
  font-style:  normal;
}

@font-face {
  font-family: 'DIN Cond';
  src:         url("https://s3.eu-central-1.amazonaws.com/truetaleof.com/FFDIN-COND-subset.woff") format("woff");

  font-weight: 400;
  font-style:  normal;
}

@font-face {
  font-family: 'Noto Emoji';
  src:         url("https://s3.eu-central-1.amazonaws.com/truetaleof.com/NotoEmoji-Medium-subset.woff2") format("woff2");

  font-weight: 500;
  font-style:  normal;
}

html {
  overflow-x: hidden;
}

body {
  font-family:             'Cadiz', 'Arial', sans-serif;
  font-size:               var(--font-size-base);
  font-weight:             400;
  color:                   var(--text-color-gunmetal);

  font-feature-settings:   'kern';
  font-kerning:            normal;
  text-rendering:          optimizeLegibility;
  -webkit-font-smoothing:  antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin:                  0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight:    600;
  letter-spacing: var(--letter-spacing-s);
  margin:         0;
}

h1 {
  font-size: var(--font-size-3xl);
}

p {
  font-size:     var(--font-size-md);
  line-height:   var(--line-height-md);
  margin-bottom: var(--space-2);
  cursor:        text;
}

ol, ul {
  list-style-type: none;
  padding-left:    0;
}

a {
  font-size:        var(--font-size-base);
  color:            var(--text-color-gunmetal);
  background-color: transparent;
}

a:hover {
  color:           var(--text-color-gunmetal);
  text-decoration: none;
}

code {
  font-family: source-code-pro, monospace;
}

hr {
  margin: 0;
}

button {
  padding:          0;
  border:           none;
  font:             inherit;
  color:            inherit;
  background-color: transparent;
  cursor:           pointer;
}

::selection {
  background-color: var(--color-yellow-highlight-5);
}

.DINCondSemiBold {
  font-family: "DIN Cond", Helvetica, sans-serif;
  font-weight: 600;
}

.NotoEmoji {
  font-family: "Noto Emoji", sans-serif;
  font-weight: 500;
}

::-webkit-scrollbar {
  width:            8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--text-color-gunmetal);
  transition:       background-color .5s;
  cursor:           pointer;
  min-height:       3.75rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-color-gunmetal-3);
}

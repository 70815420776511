:root {
  /* Font sizes */

  /* Scale: Minor Third - 1,20 */
  --font-size-xxl:            2.986rem; /*  50.76px */
  --font-size-3xl:            2.488rem; /*  42.30px */
  --font-size-2xl:            2.074rem; /*  35.25p */
  --font-size-xl:             1.728rem; /*  29.38px */
  --font-size-lg:             1.44rem; /*   24.48px */
  --font-size-md:             1.20rem; /*   20.40px = 17px (base) * 1,20 (scale) */
  --font-size-base:           17px; /*      1 rem (em?) */
  --font-size-sm:             0.885rem; /*  15.10px */

  /* Color weights */
  /* Naming system from 1 to 10 */
  /* https://www.color-hex.com/color/1c2735 */
  --text-color-gunmetal-3:    #8D939A;
  --text-color-gunmetal-4:    #49525D;
  --text-color-gunmetal-5:    #323C49;
  --text-color-gunmetal:      #1C2735;

  --color-yellow-highlight-5: hsl(48, 100%, 50%); /* #FFCE00 */
  --color-yellow-highlight-7: hsl(48, 100%, 70%);

  --background-color-white:   #FFFFFF;
  --color-green-positive:     #1B998B;
  --color-red-negative:       #B33F62;

  /* Line heights */
  --line-height-md:           1.55; /*  26px */
  --line-height-sm:           1.3; /*  22px */

  /* Letter spacings */
  --letter-spacing-sm:        0.04rem;
  --letter-spacing-s:         -0.02rem;
  --letter-spacing-normal:    0;

  /* Spacing units */
  --space-9:                  117px;
  --space-8:                  104px; /* 6.11rem; */
  --space-7:                  91px;

  --space-6:                  78px;
  --space-5:                  65px;
  --space-4:                  52px; /* 26 + 26 */

  --space-3:                  39px; /*  26 + (26/ 2)  */
  --space-2:                  26px; /*  base line-height  */
  --space-1:                  13px; /*  26 - (26/ 2)  */
  --space-0:                  6.5px; /*  (26/ 2) / 2  */

  /* Layout grid */
  --grid-column:              calc(var(--grid-column-width) + var(--grid-column-gap)); /*  89px;  */
  --grid-column-width:        3.56rem; /*  57px;  */
  --grid-column-gap:          2rem; /*  32px;  */
}

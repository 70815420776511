/*
   visually hide an element from users, but leave it accessible
   for screen readers
*/
.visuallyHidden {
  position: absolute;
  left:     -10000px;
  top:      auto;
  width:    1px;
  height:   1px;
  overflow: hidden;
}

/*
   centers and determines the width of the desktop layout
   Full layout size: 1100 px
   12 Columns + 2x Outer gutters
*/
.maxWidthDesktop {
  max-width: calc(((var(--grid-column) * 11) + var(--grid-column-width)) + (var(--grid-column-gap) * 2)); /* 1100 px */
  padding:   0 var(--grid-column-gap); /* 32px; */
  margin:    0 auto;
}

.spinner {
  display:        flex;
  flex-direction: column;
  align-items:    center;
  width:          6.3rem; /* 100px */
}

.spinnerIcon {
  display:       inline-block;
  width:         6.3rem; /* 100px */
  margin-bottom: 0.5rem; /* 8px */
}
